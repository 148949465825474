import "./StudentLife.css";
import { CellMeasurer, DonutChart, Hero, PageCaption } from "../../components";
import backgroundImage from "../../images/student-life-hero.jpg";
import Nav from "../../layout/Nav";
import { ReactComponent as BasketballIcon } from "../../icons/bball.svg";
import { ReactComponent as FootballIcon } from "../../icons/fball.svg";
import { ReactComponent as VolleyballIcon } from "../../icons/vball.svg";
import { ReactComponent as TrackIcon } from "../../icons/track.svg";
import { useInView } from "framer-motion";
import { useRef, Fragment } from "react";
import { isMobile } from "react-device-detect";
import { usePageTracking } from "../../utils/usePageTracking";

function AftercareSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <Fragment>
      <div className="StudentLife__section app-grid" ref={ref}>
        <div className="StudentLife__section__content">
          <div
            className="section-title"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            }}
          >
            Aftercare{" "}
          </div>
          <div
            className="section-p"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
            }}
          >
            The learning and fun extend beyond the school day with our Aftercare
            program. Participating students have snack, homework time and free
            play daily. Students may also enroll in Enrichment offerings.
          </div>
          <CellMeasurer>
            {({ squareRef, dimensions }) => (
              <div
                className="Academics__section__content__row "
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(12, minmax(0,1fr))",
                }}
              >
                <div
                  className="Academics__section__content__row__column "
                  style={{
                    gridColumnStart: 1,
                    gridColumnEnd: isMobile ? 13 : 8,
                  }}
                  ref={squareRef}
                >
                  <div
                    className="section-title"
                    style={{
                      opacity: isInView ? 1 : 0,
                      transition:
                        "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
                      marginTop: "24px",
                    }}
                  >
                    Enrichment + Clubs{" "}
                  </div>

                  <div
                    className="section-p"
                    style={{
                      paddingRight: isMobile ? 0 : "64px",
                      marginBottom: isMobile ? "24px" : "56px",
                      opacity: isInView ? 1 : 0,
                      transition:
                        "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s",
                    }}
                  >
                    Enrichment options include over a dozen after-school
                    activities on a trimester basis, such as Lego Engineering,
                    Chess, Sailing, Fishing, Math Circles, and more.
                  </div>

                  <div
                    className="section-p"
                    style={{
                      paddingRight: "64px",
                      marginBottom: "56px",
                      opacity: isInView ? 1 : 0,
                      transition:
                        "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                    }}
                  >
                    Club and Team offerings include recreational and charter
                    league sports, Musical Theater, Science Olympiad,
                    CyberPatriot, Taller de Arte, and Social Justice League.
                  </div>
                </div>

                {!isMobile && (
                  <div
                    className="Academics__section__content__row__column "
                    style={{
                      gridColumnStart: 8,
                      gridColumnEnd: 13,
                      opacity: isInView ? 1 : 0,
                      transition:
                        "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.6s",
                    }}
                  >
                    <img
                      src={"student-life-1.png"}
                      style={{ height: dimensions.height, width: "auto" }}
                      alt=""
                    />
                  </div>
                )}
              </div>
            )}
          </CellMeasurer>
        </div>
      </div>

      {/* <div
        style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
          marginTop: "64px",
        }}
      >
        <img src={"student-life-2.png"} alt="" />
      </div> */}
    </Fragment>
  );
}

function AthleticsSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <>
      <div
        style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
          marginTop: "64px",
        }}
      >
        <img src={"student-life-2.png"} alt="" />
      </div>
      <div className="StudentLife__section app-grid" ref={ref}>
        <div className="StudentLife__section__content ">
          <div
            className="section-title"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            }}
          >
            Athletics
          </div>

          <div
            className="section-p"
            style={{
              fontWeight: "700",
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
            }}
          >
            We value active bodies as much as active minds. Students learn
            foundational athletic skills during PE classes and have free play
            during daily recess.
          </div>

          <div
            className="section-p"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
            }}
          >
            HoLa students participate in Hoboken’s recreation program and on
            HoLa school teams in the statewide Charter School Athletic League.
            We regularly enhance our sports offerings.
          </div>
        </div>
      </div>
    </>
  );
}

function SportsSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.4 });

  return (
    <div className="StudentLife__sports app-grid" ref={ref}>
      <div className="StudentLife__sports__list">
        <div
          className="StudentLife__sports__list__item"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          <div className="StudentLife__sports__list__item__image">
            <BasketballIcon style={isMobile ? { height: "80px" } : {}} />
          </div>

          <div className="StudentLife__sports__list__item__primary">
            Basketball
          </div>

          <div className="StudentLife__sports__list__item__secondary">
            (Grades 3-8)
          </div>
        </div>

        <div
          className="StudentLife__sports__list__item"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
        >
          <div className="StudentLife__sports__list__item__image">
            <FootballIcon style={isMobile ? { height: "80px" } : {}} />
          </div>

          <div className="StudentLife__sports__list__item__primary">Soccer</div>

          <div className="StudentLife__sports__list__item__secondary">
            (Grades 6-8)
          </div>
        </div>

        <div
          className="StudentLife__sports__list__item"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
          }}
        >
          <div className="StudentLife__sports__list__item__image">
            <VolleyballIcon style={isMobile ? { height: "80px" } : {}} />
          </div>

          <div className="StudentLife__sports__list__item__primary">
            Volleyball
          </div>

          <div className="StudentLife__sports__list__item__secondary">
            (Grades 6-8)
          </div>
        </div>

        <div
          className="StudentLife__sports__list__item"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s",
          }}
        >
          <div className="StudentLife__sports__list__item__image">
            <TrackIcon style={{ height: isMobile ? "80px" : "120px" }} />
          </div>

          <div className="StudentLife__sports__list__item__primary">
            Track/Cross Country
          </div>

          <div className="StudentLife__sports__list__item__secondary">
            (Grades 6-8)
          </div>
        </div>
      </div>
    </div>
  );
}

function AventurasSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <div
      className="Academics__section app-grid"
      style={{ paddingBottom: "72px" }}
      ref={ref}
    >
      <div
        className="Academics__section__content "
        style={{
          gridColumnStart: 2,
          gridColumnEnd: 13,
        }}
      >
        <div
          className="Academics__section__content__row "
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12, minmax(0,1fr))",
          }}
        >
          <div
            className="Academics__section__content__row__column "
            style={{
              gridColumnStart: 1,
              gridColumnEnd: isMobile ? 12 : 9,
              paddingRight: isMobile ? 0 : "64px",
            }}
          >
            <div
              className="section-title"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              Aventuras Veraniegas
            </div>

            <div
              className="section-p"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
              }}
            >
              HoLa’s Aventuras Veraniegas, a summer camp open to the general
              public, takes children “around the world” to experience a variety
              of Latin American cultures.
            </div>

            <div
              className="section-p"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
              }}
            >
              HoLa’s Spanish immersion summer camp runs for five weeks every
              summer - and is a great way for students entering grades K-4 to
              have fun while developing Spanish language skills. Students will
              “explore” a different Spanish-speaking country each week – in
              addition to arts programming, local field trips and more!
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  background: "#F27224",
                  height: isMobile ? "56px" : "64px",
                  paddingLeft: "48px",
                  paddingRight: "48px",
                  fontSize: isMobile ? "16px" : "20px",
                  fontWeight: "700",
                  borderRadius: "32px",
                  marginTop: "24px",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s",
                  ...(isMobile ? { width: "100%" } : {}),
                }}
                href={
                  "https://campscui.active.com/orgs/HoLaPrograms?e4q=7873d8d6-995b-4c02-98c4-4b3552c39fe1&e4p=7f094929-949c-4331-b9d7-f39143992f81&e4ts=1681662348&e4c=active&e4e=snlvcmpscui00001load&e4rt=Safetynet&e4h=108c2dffc0a39286502739ee8868e8b2"
                }
                target={"_blank"}
                rel="noreferrer"
              >
                Sign up for summer
              </a>
            </div>
          </div>

          {!isMobile && (
            <div
              className="Academics__section__content__row__column "
              style={{
                gridColumnStart: 9,
                gridColumnEnd: 13,
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
              }}
            >
              <img
                src={"student-life-3.png"}
                style={{ marginTop: "48px" }}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ValuesSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <div className="Academics__section app-grid" ref={ref}>
      <div className="Academics__section__content ">
        <div
          className="section-title"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          HoLa Core Values
        </div>

        <div
          className="Academics__section__content__image"
          style={{
            marginBottom: "48px",
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
        >
          <img src={"academics-3.png"} alt="" />
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
          }}
        >
          HoLa’s core values define who we are as a school community and reflect
          our commitment to academic excellence, bilingualism, and the
          cultivation of character strengths. We strive for a school climate
          that is joyful, academically rigorous, and safe for all.
          {/* HoLa’s core values define who we are as a school community and reflect
          our commitment to diversity, equity, and inclusivity, regardless of
          race, ethnicity, religion, gender, gender identity, physical or mental
          ability, sexual orientation, or socioeconomic status. Our common goals
          are academic excellence, bilingualism, and the cultivation of
          character strengths. */}
        </div>

        {/* <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s",
          }}
        >
          We expect members of our school community to embody these core values
          in the classroom, in the lunchroom, in the park, and online. We strive
          for a school climate that is joyful, academically rigorous, and safe
          for all.
        </div> */}
      </div>
    </div>
  );
}

function DiversitySection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <CellMeasurer>
      {({ squareRef, dimensions }) => (
        <div className="Academics__section app-grid" ref={ref}>
          <div className="Academics__section__content ">
            <div
              className="Academics__section__content__row "
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(12, minmax(0,1fr))",
              }}
            >
              <div
                className="Academics__section__content__row__column "
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: isMobile ? 13 : 8,
                }}
                ref={squareRef}
              >
                <div
                  className="section-title"
                  style={{
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
                  }}
                >
                  Diversity at HoLa
                </div>

                <div
                  className="section-p"
                  style={{
                    paddingRight: isMobile ? 0 : "64px",
                    marginBottom: isMobile ? "24px" : "56px",
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
                  }}
                >
                  At HoLa, we are committed to being a heterogenous and
                  inclusive community with a culture of social justice, respect,
                  and equity. We actively seek to make the world more equitable
                  for people of all identities, backgrounds and beliefs,
                  starting within our own school. We commit to being a school in
                  which students, faculty, staff, families, and alumni feel
                  respected and valued for who they are. Students are better
                  educated and prepared to succeed in our global community when
                  the school community is diverse, inclusive and committed to
                  cultural proficiency.
                </div>
              </div>

              {!isMobile && (
                <div
                  className="Academics__section__content__row__column "
                  style={{
                    gridColumnStart: 8,
                    gridColumnEnd: 13,
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
                  }}
                >
                  <img
                    src={"academics-4.png"}
                    style={{ height: dimensions.height, width: "auto" }}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </CellMeasurer>
  );
}

function StudentLifeStats() {
  const ref = useRef();
  const isInView = useInView(ref, { once: true });
  return (
    <>
      <div className="Home__stats app-grid">
        <div className="Home__stats__content">
          {/* <div className="Home__stats__content__title">
            Inmersión en Español / Spanish Immersion
          </div> */}

          <div
            className="Home__stats__content__graphs"
            ref={ref}
            style={{ marginTop: "40px" }}
          >
            <div className="Home__stats__content__graphs__graph">
              <div className="Home__stats__content__graphs__graph__donut">
                <div className="Home__stats__content__graphs__graph__donut__chart">
                  {isInView && (
                    <DonutChart d={[10, 90]} colors={["#F8F8F8", "#AB1E43"]} />
                  )}
                </div>
                <div className="Home__stats__content__graphs__graph__donut__legend">
                  <div className="Home__stats__content__graphs__graph__donut__legend__primary">
                    90%
                  </div>

                  {/* <div className="Home__stats__content__graphs__graph__donut__legend__secondary">
                    10% english
                  </div> */}
                </div>
              </div>
              <div className="Home__stats__content__graphs__graph__donut__label">
                HoLa's staff is 90% Hispanic and represents over 15 different
                countries throughout the Americas and Europe.
              </div>
            </div>

            <div className="Home__stats__content__graphs__graph">
              <div className="Home__stats__content__graphs__graph__donut">
                <div className="Home__stats__content__graphs__graph__donut__chart">
                  {isInView && (
                    <DonutChart d={[20, 80]} colors={["#F8F8F8", "#F27224"]} />
                  )}
                </div>
                <div className="Home__stats__content__graphs__graph__donut__legend">
                  <div className="Home__stats__content__graphs__graph__donut__legend__primary">
                    80%
                  </div>

                  {/* <div className="Home__stats__content__graphs__graph__donut__legend__secondary">
                    40% english
                  </div> */}
                </div>
              </div>
              <div className="Home__stats__content__graphs__graph__donut__label">
                4 out of 5 of our HoLa parents studied, speak or write another
                language besides English. Our parents speak over 40+ languages
              </div>
            </div>

            <div className="Home__stats__content__graphs__graph">
              <div className="Home__stats__content__graphs__graph__donut">
                <div className="Home__stats__content__graphs__graph__donut__chart">
                  {isInView && (
                    <DonutChart d={[70, 30]} colors={["#F8F8F8", "#1FB1CC"]} />
                  )}
                </div>
                <div className="Home__stats__content__graphs__graph__donut__legend">
                  <div className="Home__stats__content__graphs__graph__donut__legend__primary">
                    30%
                  </div>

                  {/* <div className="Home__stats__content__graphs__graph__donut__legend__secondary">
                    50% english
                  </div> */}
                </div>
              </div>
              <div className="Home__stats__content__graphs__graph__donut__label">
                Close to ⅓ of our children know another language beyond English
                + Spanish - HoLa children speak over 25+ third languages.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
          marginTop: "64px",
        }}
      >
        <img src={"student-life-2.png"} alt="" />
      </div>
    </>
  );
}

export default function StudentLife() {
  usePageTracking({
    title: "Student Life",
  });

  return (
    <div className="StudentLife">
      <Nav />
      <Hero image={backgroundImage} text={"Student Life"} />
      <PageCaption
      // text={
      //   <span>
      //     <span className="bold">
      //       The unique combination of a commitment to global citizenship and
      //       bilingualism ensures our community is diverse, inclusive and
      //       innovative.
      //     </span>
      //   </span>
      // }
      />

      <ValuesSection />

      {/* <DiversitySection /> */}

      {/* <StudentLifeStats /> */}

      <AthleticsSection />

      <SportsSection />

      <AftercareSection />

      <AventurasSection />
    </div>
  );
}
