import "./PageCaption.css";
import { Fragment, useRef } from "react";
import { useInView } from "framer-motion";
import { Link } from "react-router-dom";

export default function PageCaption({ text, options }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.5 });

  if (!text) {
    return <div />;
  }
  return (
    <div className="PageCaption app-grid" ref={ref}>
      <div className="PageCaption__content">
        <div
          className="PageCaption__text"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          {text}
        </div>

        {options?.length && (
          <div
            className="PageCaption__options"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.6s",
            }}
          >
            {options.map((o) => (
              <Fragment>
                {o.type === "external" ? (
                  <a
                    className="PageCaption__options__option"
                    href={o.to}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {o.label}
                  </a>
                ) : (
                  <Link to={o.link} className="PageCaption__options__option">
                    {o.label}
                  </Link>
                )}
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
